// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-hall-of-fame-planets-navigation-tsx": () => import("../src/templates/HallOfFamePlanetsNavigation.tsx" /* webpackChunkName: "component---src-templates-hall-of-fame-planets-navigation-tsx" */),
  "component---src-templates-by-date-navigation-tsx": () => import("../src/templates/ByDateNavigation.tsx" /* webpackChunkName: "component---src-templates-by-date-navigation-tsx" */),
  "component---src-templates-navigation-tsx": () => import("../src/templates/Navigation.tsx" /* webpackChunkName: "component---src-templates-navigation-tsx" */),
  "component---src-templates-exoplanet-navigation-tsx": () => import("../src/templates/ExoplanetNavigation.tsx" /* webpackChunkName: "component---src-templates-exoplanet-navigation-tsx" */),
  "component---src-templates-simulator-container-tsx": () => import("../src/templates/SimulatorContainer.tsx" /* webpackChunkName: "component---src-templates-simulator-container-tsx" */),
  "component---src-templates-starship-scenario-tsx": () => import("../src/templates/StarshipScenario.tsx" /* webpackChunkName: "component---src-templates-starship-scenario-tsx" */),
  "component---src-pages-changelog-tsx": () => import("../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-contact-tsx": () => import("../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-credits-tsx": () => import("../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */)
}

